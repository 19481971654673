import places from "places.js";
import "./address_autocomplete.css";

const initAutocomplete = () => {
  const addressInput = document.getElementById("vendor_address");
  if (addressInput) {
    const placesAutocomplete = places({
      container: addressInput,
      aroundLatLng: "46.227638,2.213749"
    });

    addressInput.addEventListener("focusout", () => {
      placesAutocomplete.close();
    });
  }
};

initAutocomplete();
