import "./sentence.css";

const initSentence = () => {
 const sentenceContainer = document.getElementById("sentence-container");
 if(sentenceContainer) {
  const sentence = document.querySelector(".sentence");
  const author = document.querySelector(".author");
  $.getJSON('/sentences.json', function(data) {
    const nbr = Math.floor(Math.random() * data.quotes.length);
    const sentenceData = data["quotes"][nbr]['quote'];
    sentence.innerHTML = sentenceData
    const authorData = data["quotes"][nbr]['author'];
    author.innerHTML = authorData
  });
 }
}

export { initSentence };
