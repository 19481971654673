import "mapbox-gl/dist/mapbox-gl.css";
import "./mapbox.css";
import mapboxgl from "mapbox-gl";

const fitMapToMarkers = (map, features) => {
  const bounds = new mapboxgl.LngLatBounds();
  features.forEach(({ geometry }) => bounds.extend(geometry.coordinates));
  map.fitBounds(bounds, { padding: 20, maxZoom: 15 });
};

// NOTE: Affiche la carte Mapbox sur les pages qui ont l'ID vendor_map (intégrée dans le thème via un Iframe ensuite). Permet de rajouter et modifier automatiquement les vendeurs selon la DB ABO
const loadMapbox = () => {
  const mapElement = document.getElementById("vendor_map");

  if (mapElement) {
    mapboxgl.accessToken = mapElement.dataset.mapboxApiKey;

    const map = new mapboxgl.Map({
      container: "vendor_map",
      style: "mapbox://styles/damienlethiec/cjt74vrvg03i01fps9qxrhlfo"
    });

    map.scrollZoom.disable();

    map.addControl(new mapboxgl.NavigationControl());

    const vendors = JSON.parse(mapElement.dataset.vendors);

    fitMapToMarkers(map, vendors.features);

    vendors.features.forEach(marker => {
      // create a HTML element for each feature
      const el = document.createElement("div");
      el.className = "marker";
      // make a marker for each feature and add to the map
      new mapboxgl.Marker(el)
        .setLngLat(marker.geometry.coordinates)
        .setPopup(
          new mapboxgl.Popup({ offset: 5 }) // add popups
            .setHTML(
              `<div class="text-center"><h3 class="mb-1 popup_title">${marker.properties.title}</h3>${marker.properties.description}</div>`
            )
        )
        .addTo(map);
    });
  }
};

loadMapbox();
