import flatpickr from "flatpickr";
import "flatpickr/dist/themes/light.css";
import "flatpickr/dist/l10n/fr";

flatpickr(".datepicker", {
  dateFormat: "d-m-Y",
  locale: "fr",
  allowInput: true,
  altInput: false
});

const dateInput = document.querySelector(".datepicker");
if (dateInput) {
  dateInput.onkeypress = () => false;
}
