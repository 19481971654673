import { DataTable } from "simple-datatables";
import "./datatable.css";

export default function loadDataTable() {
  const datatables = document.querySelectorAll(".datatable");
  // eslint-disable-next-line func-names
  datatables.forEach(datatable => {
    // eslint-disable-next-line no-new
    new DataTable(datatable, {
      paging: false,
      labels: {
        placeholder: "Recherche",
        noRows: "Aucun résultat"
      }
    });
  });
  document.querySelectorAll(".dataTable-input").forEach(element => {
    element.classList.add("form-control");
  });
}
