import "../init";
import "../components/index";
import "../static/static";
import loadDataTable from "../components/datatable";
import { initSentence } from "../components/sentence/index";

require("turbolinks").start();

document.addEventListener("turbolinks:load", () => {
  loadDataTable();
  initSentence();
});
