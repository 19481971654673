function goToRightTab() {
  const tabs = document.querySelector(".nav-tabs");
  if (tabs) {
    const { hash } = document.location;
    if (hash) {
      $(`.nav-tabs a[href="${hash}"]`).tab("show");
      window.scrollTo(0, 0);
    }
  }
}

document.querySelectorAll(".go-to-tab-link").forEach(element => {
  element.addEventListener("click", () => {
    setTimeout(() => goToRightTab(), 100);
  });
});

goToRightTab();
