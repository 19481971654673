import Rails from "rails-ujs";
import * as ActiveStorage from "activestorage";

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

import "bootstrap/dist/js/bootstrap";
import "./index.css";

Rails.start();
ActiveStorage.start();

const application = Application.start();
const context = require.context("../components", true, /.js$/);
application.load(definitionsFromContext(context));
